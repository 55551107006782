import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import storage from '../../helpers/storage'
import { IMenuList, IResourceContext, ISidebarProps, IUserContext, IVaceContext } from '../../utils/types'
import Axios from 'axios';
import UserContext from '../../context/user/userContext'
import { useNetworkDetect, usePageRedirect } from '../../helpers/hooks';
import body from '../../helpers/body'
import { UserType } from '../../utils/enums.util';
import VaceContext from '../../context/vace/vaceContext';
import ResourceContext from '../../context/resource/resourceContext';

const SideBar = ({ collapsed, barCollapsed, expandSidebar }: ISidebarProps) => {

    const navigate = useNavigate()
    const cookie = new Cookies();

    const userContext = useContext<IUserContext>(UserContext);
    const vaceContext = useContext<IVaceContext>(VaceContext)
    const resourceContext = useContext<IResourceContext>(ResourceContext)


    const [active, setActive] = useState<string | null>('home')
    const [sub, setSub] = useState<string | null>('');
    const [sidebox, setSidebox] = useState(false);

    useEffect(() => {

        body.changeBackground('bg-mint');
        initPage();

    }, [userContext.user])

    useEffect(() => {

        if (userContext.permissionList.length > 0 && userContext.permissions.length > 0) {
            userContext.setMenuList({
                permissions: userContext.permissionList,
                userPermissions: userContext.permissions
            })
        } else {
            userContext.setDefaultMenu()
        }



    }, [userContext.permissionList, userContext.permissions])

    useNetworkDetect();
    usePageRedirect(["admin", "superadmin"]);

    const goTo = (e: any, url: string, p: string) => {
        if (e) { e.preventDefault() }
        setActive(p);

        storage.keepLegacy('sb-menu', p);
        storage.delete('sub-menu', false);
        userContext.setSidebar(false, '')

        navigate(url)
    }

    const goToSub = (e: any, url: string, t: string) => {
        if (e) { e.preventDefault() }
        setSub(t);
        storage.keepLegacy('sub-link', t);
        navigate(url);
    }

    const initPage = async () => {

        // set user type
        userContext.setUserType(userContext.getUserType());

        // activate submenu
        if (barCollapsed === true) {
            userContext.setSidebar(false, '')
        } else {

            if (collapsed === true) {
                userContext.setSidebar(false, '')
            } else {

                const sb = storage.fetch('sub-menu')

                if (sb) {
                    userContext.setSidebar(sb.active, sb.label)
                }

            }

        }

        // redirectToLogin()
        setActive(storage.fetchLegacy('sb-menu') ? storage.fetchLegacy('sb-menu') : 'home');
        setSub(storage.fetchLegacy('sub-link') ? storage.fetchLegacy('sub-link') : '');

        if (body.isObjectEmpty(userContext.user) === true) {
            userContext.getAuthUser(storage.getUserID());
        }

        if (body.isArrayEmpty(vaceContext.accounts) === true) {
            vaceContext.getAccounts(true)
        }

        userContext.getPermissions({ limit: 9999, page: 1, order: 'desc' });
        userContext.getOverview();
        vaceContext.getOverview();

        // get banks
        if (body.isArrayEmpty(vaceContext.banks) === true) {
            resourceContext.getBanks({ limit: 9999, page: 1, order: 'desc', mapped: true })
        }

    }

    const redirectToLogin = () => {

        const ut = cookie.get("userType");

        if (!storage.checkToken() && !storage.checkUserID()) {
            logout()
        } else if (ut === '' || ut === undefined || ut === null) {
            logout()
        }
    }

    const logout = async (e: any = null) => {

        if (e) e.preventDefault();

        storage.clearAuth();
        localStorage.clear();
        navigate('/login');

        // remove cookies
        cookie.remove('token');
        cookie.remove('userType');
        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`, {}, storage.getConfig());
    }

    const toggleSub = (e: any, lb: string, url: string = '') => {

        if (e) { e.preventDefault() }

        if (userContext.sidebar.active && userContext.sidebar.label === lb) {
            userContext.setSidebar(false, '')
            storage.delete('sub-menu')
        } else {

            userContext.setSidebar(true, lb)
            setActive(lb);

            // local storage
            storage.keepLegacy('sb-menu', lb)
            storage.keep('sub-menu', { active: true, label: lb })
        }

        if (url) { navigate(url) }

        if (collapsed && barCollapsed) {
            expandSidebar(e)
        }

    }

    const closeSub = (e: any) => {
        if (e) { e.preventDefault() }
        userContext.setSidebar(false, '')
        storage.delete('sub-menu', false)
    }

    return (
        <>

            <div className='ui-monitor'>
                <div className='d-flex'>
                    <div />
                    <div className='ml-auto'>
                        <Link to='/' className='pullin--btn onblack'>
                            <span
                                className='fe fe-arrow-left fs-20'
                                style={{ color: '#2F80ED' }}
                            />
                        </Link>
                    </div>
                </div>
            </div>

            <section id="ui-sidebar" className={`ui-sidebar ${collapsed && collapsed === true ? 'sdbr--cllps' : 'sdbr--open'}`}>

                {/* secondary */}
                <div className={`ui-sidebar-secondary head-shift ${userContext.sidebar.active ? 'open' : 'close'}`}>

                    <div className={`bar-head`}>

                        <Link to={``} className='brand-dindigo'>
                            <span className='font-aeonik-medium brand-purple fs-12 pdr ui-upcase'>{userContext.sidebar.label}</span>
                        </Link>
                        <Link onClick={(e) => closeSub(e)} to="" className='ml-auto ui-relative' style={{ opacity: '0.75', top: '1px' }}><span className='fe fe-x fs-15 brand-purple-dark'></span></Link>

                    </div>

                    <div className='bar-body'>

                        <ul className='links'>

                            {
                                userContext.menu.length > 0 &&
                                userContext.menu.map((menu, index) =>

                                    <Fragment key={index}>

                                        {
                                            userContext.sidebar.label === menu.label &&
                                            menu.submenu.length > 0 &&
                                            menu.submenu.map((submenu, index) =>

                                                <Fragment key={`${menu.label}-${submenu.name}`}>
                                                    <li>
                                                        <Link onClick={(e) => goToSub(e, submenu.url, submenu.name)} to="">
                                                            <span className={`font-aeonik-medium fs-13 text-capitalize ${sub === submenu.name ? 'brand-purple' : ''}`}>{submenu.name}</span>
                                                            <span className={`fe fe-chevron-right ml-auto ${sub === submenu.name ? 'brand-purple' : ''}`}></span>
                                                        </Link>
                                                    </li>
                                                </Fragment>

                                            )
                                        }

                                    </Fragment>

                                )
                            }

                            {
                                userContext.sidebar.label === 'corporate-details' &&
                                <>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, `/dashboard/users/corporates/${vaceContext.business ? vaceContext.business._id : ''}`, 'corp-profile')} to="">
                                            <span className={`font-aeonik-medium fs-13 text-capitalize ${sub === 'corp-profile' ? 'brand-purple' : ''}`}>Business Profile</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'corp-profile' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, `/dashboard/users/corporates/settings/${vaceContext.business ? vaceContext.business._id : ''}`, 'corp-settings')} to="">
                                            <span className={`font-aeonik-medium fs-13 text-capitalize ${sub === 'corp-settings' ? 'brand-purple' : ''}`}>Settings</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'corp-settings' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, `/dashboard/users/corporates/transactions/${vaceContext.business ? vaceContext.business._id : ''}`, 'corp-transactions')} to="">
                                            <span className={`font-aeonik-medium fs-13 text-capitalize ${sub === 'corp-transactions' ? 'brand-purple' : ''}`}>Transactions</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'corp-transactions' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={(e) => goToSub(e, `/dashboard/users/corporates/merchants/${vaceContext.business ? vaceContext.business._id : ''}`, 'merchants')} to="">
                                            <span className={`font-aeonik-medium fs-13 text-capitalize ${sub === 'merchants' ? 'brand-purple' : ''}`}>Merchants</span>
                                            <span className={`fe fe-chevron-right ml-auto ${sub === 'merchants' ? 'brand-purple' : ''}`}></span>
                                        </Link>
                                    </li>
                                </>
                            }

                        </ul>

                    </div>
                </div>

                {/* primary */}
                <div id="ui-sidebar-primary" className={`ui-sidebar-primary ${collapsed && collapsed === true ? 'sdbr--cllps' : 'sdbr--open'}`}>

                    <div id="ui-sidebar-primary-header" className="ui-sidebar-primary-header">

                        <Link to="/dashboard"><img className="logo" width={"180px"} src="../../../../../images/assets/logo@colored.svg" alt="" /></Link>

                    </div>

                    <div className='ui-sidebar-primary-body'>

                        <div className="ui-separate-small ui-show-mobile-only"></div>

                        <ul id="ui-sidebar-primary-links" className={`ui-sidebar-primary-links primary-nav pdl0 pdb0`}>

                            {
                                (userContext.getUserType() === UserType.ADMIN || userContext.getUserType() === UserType.SUPERADMIN) &&
                                userContext.menu.length > 0 &&
                                userContext.menu.map((menu: IMenuList, index: number) =>
                                    <Fragment key={index}>
                                        {
                                            menu.name !== 'logout' &&
                                            <>

                                                <li className={`nav-list ${active === menu.label ? 'active' : ''}`}>
                                                    <Link onClick={(e) => { menu.submenu.length > 0 ? toggleSub(e, menu.label, '') : goTo(e, menu.url, menu.label) }} to='' className='ui-icon-animate link' title={body.captialize(menu.label)}>
                                                        <span style={{ top: menu.icon.top, left: menu.icon.left }} className={`ui-relative terra-${menu.icon.name} ts-icon ${menu.icon.label} ${menu.icon.flip ? 'flip' : ''} ${active === menu.label ? 'active' : ''}`}>
                                                            {
                                                                menu.icon.paths.map((path, index) => {
                                                                    return <i key={index} className={`${path} fs-${menu.icon.size}`}></i>
                                                                })
                                                            }
                                                        </span>
                                                        <span style={{ position: 'relative', left: menu.style.left, top: menu.style.top }} className={`lnk--text sb-text font-${active === menu.label ? 'aeonik-bold' : 'aeonik-medium'} fs-14`}>
                                                            {body.captialize(menu.name)}
                                                        </span>
                                                    </Link>
                                                </li>

                                                {menu.position === 5 && <div className="ui-line"></div>}

                                            </>
                                        }
                                    </Fragment>
                                )
                            }


                        </ul>

                        <ul id="ui-sidebar-primary-links" className={`ui-sidebar-primary-links`}>

                            <li className={`nav-list`}>
                                <Link onClick={(e) => logout(e)} to='' className='ui-icon-animate link' title='Logout'>
                                    <span style={{ top: '1px', left: '4px' }} className='ui-relative terra-power ts-icon'>
                                        <i className='path1 fs-20'></i>
                                        <i className='path2 fs-20'></i>
                                    </span>
                                    <span style={{ position: 'relative', left: '-4px' }} className='lnk--text sb-text font-aeonik-medium fs-14'>
                                        Logout
                                    </span>
                                </Link>
                            </li>

                        </ul>

                    </div>

                </div>

            </section >

        </>
    )

}

export default SideBar

{/* <li className={`drop ${ active === 'settings' ? 'active' : '' }`}>
    <Link onClick={ (e) => {
        openDrop(e,'settings', 'settings');
        }} className='ui-icon-animate' title='Launch' >
        <span className='concreap-icon'><img src="../../../images/icons/dsettings.svg" alt="icon" /></span>
        <span className='lnk--text font-aeonik fs-15'>Settings</span>
        <span className="ml-auto fe fs-24" style={{position: 'relative', top: '3px', left: '8px'}}></span>
    </Link>

    <div className={`ui-sidebar-dropdown ${dropType === 'trade' ? 'is-open' : ''}`}>

        <ul className='ui-sidebar-primary-links'>

            <li>
                <Link onClick={(e) => goto(e, '/salex/launch', 'launch')} to='' className='ui-icon-animate' title='Basic info'>
                <span className='lnk--text fs-13 font-montserrat'>
                    Dashboard
                </span>
                </Link>
            </li>

            <li>
                <Link onClick={(e) => goto(e, '/salex/launch/start-sale', 'launch')} to='' className='ui-icon-animate' title='Basic info'>
                <span className='lnk--text  fs-13 font-montserrat'>
                    Start Sale
                </span>
                </Link>
            </li>

            <li>
                <Link onClick={(e) => goto(e, '/salex/launch/manage', 'launch')} to='' className='ui-icon-animate' title='Basic info'>
                <span className='lnk--text  fs-13 font-montserrat'>
                    Manage Presale
                </span>
                </Link>
            </li>

        </ul>

    </div>
</li> */}