import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';

import Axios from 'axios';
import storage from '../../helpers/storage'
import loader from '../../helpers/loader'
import { io, Socket } from 'socket.io-client';

import VaceContext from './vaceContext';
import VaceReducer from './vaceReducer';

import {
    SET_LOADING,
    UNSET_LOADING,
    GET_BUSINESS,
    GET_WALLET,
    GET_ACCOUNTS,
    GET_ACCOUNT,
    GET_BILLERS,
    SET_WALLET,
    GET_TRANSACTIONS,
    SET_PAGINATION,
    SET_TOTAL,
    SET_COUNT,
    GET_BENEFICIARIES,
    GET_BUSINESS_BANKS,
    SET_SEARCH,
    GET_BILL_CATEGORIES,
    GET_MOBILE_PLANS,
    GET_PRODUCTS,
    GET_PRODUCT,
    SET_SPLITS,
    GET_PAYMENT_LINKS,
    GET_PAYMENT_LINK,
    GET_VACE_OVERVIEW,
    SET_RESPONSE,
    SET_BANK,
    GET_TRANSACTION,
    GET_SETTLEMENTS,
    GET_SETTLEMENT,
    GET_HISTORIES,
    GET_HISTORY,
    GET_CHARGEBACKS,
    GET_CHARGEBACK,
    GET_REFUNDS,
    GET_REFUND,
    GET_PROVIDER,
    GET_PROVIDERS,
    SET_INVOICE_ITEMS,
    GET_INVOICES,
    GET_INVOICE,
    GET_BUSINESSES,
    GET_VACE_GRAPH,
    GET_BUSINESS_SETTINGS,
    SET_FILTER_OPTIONS,
    SET_ANALYTICS,
    SET_CURRENCY,
    SET_BUSINESS_MERCHANTS
} from '../types'
import { IBusinessMerchant, IFilterOptions, IInvoiceItem, IListQuery, IPagination, IProductSplit, IResponse, ISearchProps, IVaceOverview } from '../../utils/types';
import helperService from '../../utils/function.util';
import { CurrencyType, NotPermittedType } from '../../utils/enums.util';
import { SetGraphDataDTO, SetYearChartDTO } from '../../dtos/analytics.dto';
import body from '../../helpers/body';

const VaceState = (props: any) => {

    const cookie = new Cookies();

    const exp = new Date(
        Date.now() + 70 * 24 * 60 * 60 * 1000
    )

    const navigate = useNavigate()
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    const initialState = {
        overview: {},
        analytics: {},
        filter: {},
        graph: {},
        businesses: [],
        business: {},
        merchants: [],
        settings: {},
        wallet: {},
        accounts: [],
        billers: [],
        biller: {},
        currency: CurrencyType.NGN,
        dataPlans: [],
        splits: [],
        bills: [],
        account: {},
        beneficiaries: [],
        beneficiary: {},
        providers: [],
        provider: {},
        settlements: [],
        settlement: {},
        histories: [],
        history: {},
        banks: [],
        bank: {},
        products: [],
        product: {},
        paymentLinks: [],
        paymentLink: {},
        transactions: [],
        transaction: {},
        chargebacks: [],
        chargeback: {},
        refunds: [],
        refund: {},
        invoices: [],
        invoice: {},
        invoiceItems: [],
        total: 0,
        count: 0,
        pagination: {},
        progress: 0,
        loading: false,
        response: {},
        search: {
            error: false,
            message: '',
            data: []
        },
    }

    const [state, dispatch] = useReducer(VaceReducer, initialState);

    const logout = async () => {

        storage.clearAuth();
        localStorage.clear();
        cookie.remove('token');
        cookie.remove('userType');

        navigate('/login');
        // wait for logout API
        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`, {}, storage.getConfig());
    }

    const getOverview = async () => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/users/overview`, storage.getConfigWithBearer())
            .then((resp) => {

                const overview: IVaceOverview = resp.data.data;

                dispatch({
                    type: GET_VACE_OVERVIEW,
                    payload: resp.data.data
                });

                setGraphData({ overview });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get overview ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get overview ${err}`)

                }

            })

    }

    /**
     * @name getBusinesses
     * @param data 
     */
    const getBusinesses = async (data: IListQuery): Promise<any> => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BUSINESSES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get businesses list ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get businesses list ${err}`)

                }

            })

    }

    /**
     * @name filterBusinesses
     * @param type 
     * @param data 
     */
    const filterBusinesses = async (type: string, data: IListQuery): Promise<any> => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;
        let payload = {
            type: type
        }

        setLoading()
        await Axios.post(`${process.env.REACT_APP_VACE_URL}/businesses/filter?${q}`, { ...payload }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BUSINESSES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get businesses list ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get businesses list ${err}`)

                }

            })

    }

    /**
     * @name getBusiness
     * @param id 
     */
    const getBusiness = async (id: string): Promise<any> => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BUSINESS,
                    payload: resp.data.data
                });

                if (resp.data.data.settings) {
                    dispatch({
                        type: GET_BUSINESS_SETTINGS,
                        payload: resp.data.data.settings
                    });
                }

                if (resp.data.data.merchants) {
                    dispatch({
                        type: SET_BUSINESS_MERCHANTS,
                        payload: resp.data.data.merchants
                    });
                }

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get business ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get business ${err}`)

                }

            })

    }

    /**
     * @name getProviders
     * @param data 
     */
    const getProviders = async (data: IListQuery) => {

        const { currency, limit, page, select, order, hr } = data;

        let q: string = '';

        if (hr && hr === true) {
            q = `limit=${limit ? limit.toString() : '20'}&page=${page ? page.toString() : "1"}&order=${order ? order : 'desc'}&currency=USD&hr=true`;
        } else {
            q = `limit=${limit ? limit.toString() : '20'}&page=${page ? page.toString() : "1"}&order=${order ? order : 'desc'}&currency=${currency ? currency : 'NGN'}`;
        }


        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/providers/all?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PROVIDERS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get providers ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get providers ${err}`)

                }

            })

    }

    /**
     * @name getWallet
     * @param id 
     */
    const getWallet = async (admin: boolean = false, id?: string,): Promise<any> => {

        let apiUrl: string = '';

        if (admin === false) {
            apiUrl = `${process.env.REACT_APP_VACE_URL}/wallets/${id}`
        } else {
            apiUrl = `${process.env.REACT_APP_VACE_URL}/owner/wallet`
        }

        setLoading();
        await Axios.get(apiUrl, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_WALLET,
                    payload: resp.data.data
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet details ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet details ${err}`)

                }

            })

    }

    /**
     * @name getAccounts
     * @param id 
     */
    const getAccounts = async (admin: boolean): Promise<any> => {

        let apiUrl: string = '';

        if (admin) {
            apiUrl = `${process.env.REACT_APP_VACE_URL}/owner/accounts?order=desc`;
        } else {
            apiUrl = `${process.env.REACT_APP_VACE_URL}/accounts`;
        }

        setLoading()
        await Axios.get(apiUrl, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_ACCOUNTS,
                    payload: resp.data.data
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet details ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet details ${err}`)

                }

            })

    }

    /**
     * @name getTransactions
     * @param id 
     * @param limit 
     * @param page 
     */
    const getTransactions = async (data: IListQuery, type?: string) => {

        let apiUrl = '';
        const { limit, page, select, order, admin } = data;

        if (admin && admin === true) {
            const q = `type=${type ? type : 'all'}&limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;
            apiUrl = `${process.env.REACT_APP_VACE_URL}/owner/transactions?${q}`
        } else {
            const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;
            apiUrl = `${process.env.REACT_APP_VACE_URL}/transactions?${q}`
        }

        setLoading()
        await Axios.get(apiUrl, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status && status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (data || errors || message) {

                    console.log(`Error! Could not get all transactions ${errors.length > 0 ? errors[0] : message}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get all transactions ${err}`)

                }

            })

    }

    /**
     * @name getTransaction
     * @param id 
     */
    const getTransaction = async (id: string) => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/transactions/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                if (resp.data.data.providerData) {
                    const dataEntries = helperService.objectToArray(resp.data.data.providerData);
                    resp.data.data.providerData = dataEntries;
                }

                dispatch({
                    type: GET_TRANSACTION,
                    payload: resp.data.data
                });

                if (resp.data.data.refunds && resp.data.data.refunds.length > 0) {
                    dispatch({
                        type: GET_REFUNDS,
                        payload: resp.data.data.refunds
                    })
                }

                if (resp.data.data.chargeback && resp.data.data.chargeback) {
                    dispatch({
                        type: GET_CHARGEBACK,
                        payload: resp.data.data.chargeback
                    })
                }

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get transaction ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get transaction ${err}`)

                }

            })

    }

    /**
     * @name getWalletTransactions
     * @param id 
     * @param data 
     */
    const getWalletTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/wallets/transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet transactions ${err}`)

                }

            })

    }

    /**
     * @name getLinkTransactions
     * @param id 
     * @param data 
     */
    const getLinkTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/paymentlinks/transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet transactions ${err}`)

                }

            })

    }

    /** 
     * @name getBillers
     * @description get all billers information
     */
    const getBillers = async () => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/vas/billers`, storage.getConfigWithBearer())
            .then((resp) => {
                dispatch({
                    type: GET_BILLERS,
                    payload: resp.data.data
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    /** 
     * @name getMobileDataPlans
     * @description get mobile data plans
     */
    const getMobileDataPlans = async (netwrok: string) => {

        setLoading()

        await Axios.post(`${process.env.REACT_APP_VACE_URL}/vas/mobile-data-plans`, { networkName: netwrok }, storage.getConfigWithBearer())
            .then((resp) => {
                dispatch({
                    type: GET_MOBILE_PLANS,
                    payload: resp.data.data
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    /** 
     * @name getBillCategories
     * @description get all billers sub categories information
     */
    const getBillCategories = async (id: number) => {

        setLoading()

        await Axios.post(`${process.env.REACT_APP_VACE_URL}/vas/biller-sub-categories`, { categoryId: id }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BILL_CATEGORIES,
                    payload: resp.data.data
                })

            })
            .catch((err) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get billers subcategories ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get billers subcategories ${err}`)

                }
            })
    }

    /**
     * @name getBusinessBanks
     * @param id 
     */
    const getBusinessBanks = async (id: string) => {

        let userId: string = id ? id : storage.getUserID();

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/banks/${userId}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BUSINESS_BANKS,
                    payload: resp.data.data
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get banks ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get banks ${err}`)

                }

            })

    }

    /**
     * @name getBusinessBeneficiaries
     * @param id 
     * @param limit 
     * @param page 
     */
    const getBusinessBeneficiaries = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;
        let userId: string = id ? id : storage.getUserID();

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/beneficiaries/${userId}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BENEFICIARIES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get beneficiaries ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get beneficiaries ${err}`)

                }

            })

    }

    /**
     * @name getSettlements
     * @param data 
     */
    const getSettlements = async (data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/settlements?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_SETTLEMENTS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get settlements ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get settlements ${err}`)

                }

            })

    }

    /**
     * @name getSettlement
     * @param id 
     */
    const getSettlement = async (id: string) => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/settlements/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_SETTLEMENT,
                    payload: resp.data.data
                });
            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get settlement ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get settlement ${err}`)

                }

            })

    }

    /**
     * @name getSettlementBusinesses
     * @param id 
     * @param data 
     */
    const getSettlementBusinesses = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/settlements/businesses/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BUSINESSES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get settlement businesses ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get settlement businesses ${err}`)

                }

            })

    }

    /**
     * @name getSettlementHistories
     * @param data 
     */
    const getSettlementHistories = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/settlements/histories/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_HISTORIES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get settlement histories ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get settlement histories ${err}`)

                }

            })

    }

    /**
     * @name getSettlementTransactions
     * @param id 
     * @param data 
     */
    const getSettlementTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order, type } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}${type ? '&type=' + type : ''}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/settlements/transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get wallet transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get wallet transactions ${err}`)

                }

            })

    }

    /**
     * @name getChargebacks
     * @param data 
     */
    const getChargebacks = async (data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/chargebacks?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_CHARGEBACKS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get chargebacks ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get chargebacks ${err}`)

                }

            })

    }

    /**
     * @name getChargeback
     * @param id 
     */
    const getChargeback = async (id: string) => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/chargebacks/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_CHARGEBACK,
                    payload: resp.data.data
                });

                if (resp.data.data.transaction) {
                    dispatch({
                        type: GET_TRANSACTION,
                        payload: resp.data.data.transaction
                    });
                }
            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get chargeback ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get chargeback ${err}`)

                }

            })

    }

    /**
     * @name getRefunds
     * @param data 
     */
    const getRefunds = async (data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/refunds?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_REFUNDS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get refunds ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get refunds ${err}`)

                }

            })

    }

    /**
     * @name getRefund
     * @param id 
     */
    const getRefund = async (id: string) => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/refunds/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_REFUND,
                    payload: resp.data.data
                });

                if (resp.data.data.transaction) {
                    dispatch({
                        type: GET_TRANSACTION,
                        payload: resp.data.data.transaction
                    });
                }

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get refund ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get refund ${err}`)

                }

            })

    }

    /**
     * @name getProducts
     * @param id 
     * @param limit 
     * @param page 
     */
    const getProducts = async (data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/products?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PRODUCTS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get products ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get products ${err}`)

                }

            })

    }

    /**
     * @name getPaymentLinks
     * @param data 
     */
    const getPaymentLinks = async (data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/paymentlinks?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PAYMENT_LINKS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get payment links ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get payment links ${err}`)

                }

            })

    }

    /**
     * @name getPaymentLink
     * @param id 
     */
    const getPaymentLink = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/paymentlinks/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PAYMENT_LINK,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get payment link ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get payment link ${err}`)

                }

            })

    }

    /**
     * @name getPaymentLinkByLabel
     * @param label 
     */
    const getPaymentLinkByLabel = async (label: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/paymentlinks/url/${label}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PAYMENT_LINK,
                    payload: resp.data.data
                });

            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (status === 422) {
                    message = 'This payment details is currently deactivated. Contact business to enable you to pay.'
                } else if (status === 403) {
                    message = 'This payment configuration is incorrect. Contact business to enable you to pay'
                } if (status === 404) {
                    message = 'This payment details does not exist on Terraswitch.'
                }

                setResponse({
                    data,
                    message,
                    status,
                    errors,
                    error
                })

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get payment link ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get payment link ${err}`)

                }

                unsetLoading()

            })

    }

    const getInvoices = async (data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/invoices?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_INVOICES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get business invoices ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get business invoices ${err}`)

                }

            })

    }

    const getInvoice = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/invoices/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_INVOICE,
                    payload: resp.data.data
                });

                setInvoiceItems(resp.data.data.items);

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get an invoice ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get an invoice ${err}`)

                }

            })

    }

    const getInvoiceByCode = async (code: string, preview: boolean) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/invoices/by-code/${code}`, storage.getConfigWithBearer())
            .then((resp) => {

                if (!resp.data.data.payment) {

                    if (preview === false) {

                        setResponse({
                            data: null,
                            message: 'This invoice is not properly confgured. No payment link attached.',
                            status: 400,
                            errors: [],
                            error: true
                        })

                    } else {

                        dispatch({
                            type: GET_INVOICE,
                            payload: resp.data.data
                        });

                        setInvoiceItems(resp.data.data.items);

                    }


                } else {

                    dispatch({
                        type: GET_INVOICE,
                        payload: resp.data.data
                    });

                    setInvoiceItems(resp.data.data.items);
                }





            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (status === 422) {
                    message = 'This invoice is currently deactivated. Contact business to enable you to view details.'
                } if (status === 404) {
                    message = 'This invoice does not exist on Terraswitch.'
                }

                setResponse({
                    data,
                    message,
                    status,
                    errors,
                    error
                })

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get invoice ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get invoice ${err}`)

                }

                unsetLoading()

            })

    }

    const getInvoiceTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/invoices/transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get invoice transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get invoice transactions ${err}`)

                }

            })

    }

    const getCorporateTransactions = async (id: string, data: IListQuery) => {

        const { limit, page, select, order } = data;

        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()
        await Axios.get(`${process.env.REACT_APP_VACE_URL}/businesses/transactions/${id}?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTIONS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get Corporate transactions ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get Corporate transactions ${err}`)

                }

            })

    }

    /**
     * @name getProduct
     * @param id 
     */
    const getProduct = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_VACE_URL}/products/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PRODUCT,
                    payload: resp.data.data
                });

                // if (resp.data.data.splits.length > 0) {

                //     const allSplits = resp.data.data.splits.map((x: any) => {

                //         let split: IProductSplit = {
                //             type: x.type,
                //             value: x.value,
                //             label: x.label,
                //             flat: x.type === 'flat' ? true : false,
                //             percentage: x.type === 'percentage' ? true : false,
                //             dest: x.destination,
                //             bank: {
                //                 accountName: x.bank.accountName,
                //                 accountNo: x.bank.accountNo,
                //                 bankCode: x.bank.bankCode,
                //                 bankName: x.bank.bankName
                //             }
                //         }

                //         return split;

                //     })

                //     setSplits(allSplits);

                // }

            })
            .catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get product ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get product ${err}`)

                }

            })

    }

    const setPagination = (data: any) => {

        dispatch({
            type: SET_PAGINATION,
            payload: data
        })

    }

    const setSearch = ({ error, message, data }: Partial<ISearchProps>) => {
        dispatch({
            type: SET_SEARCH,
            payload: { error, message, data }
        })
    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    const unsetLoading = () => {
        dispatch({
            type: UNSET_LOADING,
        })
    }

    const setWallet = (data: any) => {
        dispatch({
            type: GET_WALLET,
            payload: data
        })
    }

    const setPaymentLink = (data: any) => {
        dispatch({
            type: GET_PAYMENT_LINK,
            payload: data
        })
    }

    const setSplits = (data: Array<IProductSplit>) => {
        dispatch({
            type: SET_SPLITS,
            payload: data
        })
    }

    const setResponse = (data: any) => {
        dispatch({
            type: SET_RESPONSE,
            payload: data
        })
    }

    const setBank = (data: any) => {
        dispatch({
            type: SET_BANK,
            payload: data
        })
    }

    const setTransaction = (data: any) => {
        dispatch({
            type: GET_TRANSACTION,
            payload: data
        })
    }

    const setProviders = (data: any) => {
        dispatch({
            type: GET_PROVIDERS,
            payload: data
        })
    }

    const setInvoiceItems = (data: Array<IInvoiceItem>) => {
        dispatch({
            type: SET_INVOICE_ITEMS,
            payload: data
        })
    }

    const setInvoice = (data: any) => {
        dispatch({
            type: GET_INVOICE,
            payload: data
        })
    }

    /**
     * @name setGraphData
     * @param data 
     */
    const setGraphData = (data: SetGraphDataDTO) => {

        const { overview } = data;

        let graph = overview.wallet.graph;
        let yearly = setYearChartData({ income: graph.income, transactions: graph.transactions, type: 'yearly' });
        let latest = setCBkGraphData(overview.chargebacks.graph.latest);
        let txnPie = setTransactionPie(overview.transactions.analytics);

        dispatch({
            type: GET_VACE_GRAPH,
            payload: {
                wallet: graph,
                yearChart: yearly.yearChart,
                series: yearly.series,
                labels: yearly.labels,
                cbkLatest: latest,
                transactionPie: txnPie
            }
        })


    }

    /**
     * @name setYearChartData
     * @param data 
     * @returns 
     */
    const setYearChartData = (data: SetYearChartDTO): { series: Array<any>, labels: Array<string>, yearChart: any } => {

        let { transactions, income, type } = data;
        let result: { series: Array<any>, labels: Array<string>, yearChart: any } = { series: [], labels: [], yearChart: {} }

        let labels: Array<any> = [], incomeData: Array<any> = [],
            txnData: Array<any> = [], source: Array<any> = [], series: Array<any> = [];

        if (type === 'yearly') {

            // process labels and income
            for (let i = 0; i < income.length; i++) {

                let item = income[i];

                labels.push(body.captialize(item.label));
                incomeData.push(item.total)

            }

            // process transactions
            for (let i = 0; i < transactions.length; i++) {

                let item = transactions[i];
                txnData.push(item.total)

            }

            // capture source
            source = [
                {
                    name: 'Collections',
                    data: incomeData
                },
                {
                    name: 'Transactions',
                    data: txnData
                }
            ]

            // capture series
            source.forEach((x) => {

                series.push({
                    name: x.name,
                    stack: "Total",
                    data: x.data,
                    type: 'line',
                    colorBy: 'data',
                    lineStyle: {
                        color: x.name === 'income' ? '#3FCD78' : '#3990E0'
                    },
                })

            });

            // save details;
            result.series = series;
            result.labels = labels;
            result.yearChart = { labels, source }

        }

        return result;

    }

    /**
     * @name setCBkGraphData
     * @param data 
     * @returns 
     */
    const setCBkGraphData = (data: Array<any>): Array<{ name: string, amt: number }> => {

        let result: Array<{ name: string, amt: number }> = [];

        for (let i = 0; i < data.length; i++) {

            let item = data[i];

            result.push({
                name: item.name ? item.name : `${0 + 1}`,
                amt: item.total ? item.total : 1
            })

        }

        if (result.length > 0 && result.length < 8) {

            let diff = 8 - result.length;
            for (let i = 0; i < diff; i++) {

                result.push({
                    name: `0${i + 2}`,
                    amt: 1
                });

            }

        } else {

            for (let i = 0; i < 8; i++) {

                result.push({
                    name: `0${i + 1}`,
                    amt: i === 0 ? 0 : 0
                });

            }
        }


        return result;

    }

    /**
     * @name setTransactionPie
    */
    const setTransactionPie = (data: any): Array<{ name: string, value: number }> => {

        let result: Array<{ name: string, value: number }> = [];

        if (data.successful && data.successful) {
            result.push({
                name: 'Successful',
                value: data.successful.amount
            })
        }

        if (data.successful && data.failed) {
            result.push({
                name: 'Failed',
                value: data.failed.amount
            })
        }

        if (data.successful && data.refunded) {
            result.push({
                name: 'Refunded',
                value: data.refunded.amount
            })
        }

        return result;

    }

    const setFilterOptions = (data: IFilterOptions) => {

        dispatch({
            type: SET_FILTER_OPTIONS,
            payload: data
        })

    }

    /**
     * @name setAnalytics
     * @param data 
     */
    const setAnalytics = (data: any) => {

        dispatch({
            type: SET_ANALYTICS,
            payload: data
        })

    }


    const setCurrency = (data: string) => {

        dispatch({
            type: SET_CURRENCY,
            payload: data
        })

    }

    const setMerchants = (data: Array<IBusinessMerchant>) => {

        dispatch({
            type: SET_BUSINESS_MERCHANTS,
            payload: data
        })

    }

    return <VaceContext.Provider
        value={{
            overview: state.overview,
            filter: state.filter,
            analytics: state.analytics,
            graph: state.graph,
            businesses: state.businesses,
            merchants: state.merchants,
            business: state.business,
            settings: state.settings,
            wallet: state.wallet,
            accounts: state.accounts,
            account: state.account,
            paymentLinks: state.paymentLinks,
            paymentLink: state.paymentLink,
            splits: state.splits,
            billers: state.billers,
            currency: state.currency,
            biller: state.biller,
            dataPlans: state.dataPlans,
            bills: state.bills,
            beneficiaries: state.beneficiaries,
            beneficiary: state.beneficiary,
            providers: state.providers,
            provider: state.provider,
            chargebacks: state.chargebacks,
            chargeback: state.chargeback,
            refunds: state.refunds,
            refund: state.refund,
            invoices: state.invoices,
            invoice: state.invoice,
            invoiceItems: state.invoiceItems,
            banks: state.banks,
            bank: state.bank,
            products: state.products,
            product: state.product,
            transactions: state.transactions,
            transaction: state.transaction,
            settlements: state.settlements,
            settlement: state.settlement,
            histories: state.histories,
            history: state.history,
            search: state.search,
            total: state.total,
            count: state.count,
            pagination: state.pagination,
            progress: state.progress,
            loading: state.loading,
            response: state.response,
            getOverview,
            getBusinesses,
            filterBusinesses,
            getBusiness,
            getProviders,
            getProducts,
            getProduct,
            getPaymentLinks,
            getPaymentLink,
            getPaymentLinkByLabel,
            getInvoices,
            getInvoice,
            getInvoiceByCode,
            getInvoiceTransactions,
            getCorporateTransactions,
            getBusinessBanks,
            getBusinessBeneficiaries,
            getWallet,
            getAccounts,
            getTransactions,
            getTransaction,
            getWalletTransactions,
            getLinkTransactions,
            getSettlements,
            getSettlement,
            getSettlementTransactions,
            getSettlementBusinesses,
            getSettlementHistories,
            getChargebacks,
            getChargeback,
            getRefunds,
            getRefund,
            setSearch,
            setWallet,
            getBillers,
            getBillCategories,
            getMobileDataPlans,
            setAnalytics,
            setFilterOptions,
            setSplits,
            setPaymentLink,
            setLoading,
            setBank,
            setResponse,
            setInvoiceItems,
            setInvoice,
            setProviders,
            setTransaction,
            setCurrency,
            setMerchants,
            setPagination,
            unsetLoading,
        }}
    >
        {props.children}

    </VaceContext.Provider>

}

export default VaceState